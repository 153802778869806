import React from "react"

import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/updates.scss"

let faces = ["⋟﹏⋞", "T⌓T", "つ﹏<."]

let faceTmp = faces[Math.floor(Math.random() * faces.length)]

const WallPunk = () => (
  <Layout showFooter={false} glitchedHeader={true} staticTheme={0}>
    <SEO
      title="Wall Punk"
      description={"A brand new VR Rhythm / Action game from Vitamin Games!"}
    />
    <div className="updates">
      <div className="message">
        <div className="logo">
          <Image filename={"logos/wallpunk.png"} />
        </div>
        <h3 className="quote">
          Sorry but <b>WallPunk</b> isn't ready to be shown off yet!<br /><br />
          More news to come in 2022!
        </h3>
        <h3 className="face right">{faceTmp}</h3>
      </div>
    </div>
  </Layout>
)

export default WallPunk
